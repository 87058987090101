/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import toast, { Toaster } from "react-hot-toast";
import { motion } from "framer-motion";
import axios from "axios";

/* COMPONENT IMPORTS */
import BlogBox from "../../components/blog-box";
import TestimonialBox from "../../components/testimonial-box";

/* ICON IMPORTS */
import PlayIcon from "@iconscout/react-unicons/icons/uil-play";
import CallIcon from "@iconscout/react-unicons/icons/uil-phone-volume";
import CalendarIcon from "@iconscout/react-unicons/icons/uil-calendar-alt";

/* IMAGE IMPORTS */
import BlogImage01 from "../../assets/blogs/signs-of-male-infertility.webp";
import BlogImage02 from "../../assets/blogs/is-ivf-painful.webp";
import BlogImage03 from "../../assets/blogs/sign-of-successful-embryo-transfer.webp";
import BlogImage04 from "../../assets/blogs/secondary-infertility.webp";
import AboutVideoImg from "../../assets/photo-with-certifications.webp";
import About from "../../assets/about-vivek-kakkad.png";
import Question01 from "../../assets/question-01.webp";
import Question02 from "../../assets/question-02.webp";
import VivekProfile from "../../assets/personal-photos/vivek-kakkad-02.webp";
import ExpertiseIcon from "../../assets/icons/obstetrical.webp";
import ExpertiseIcon02 from "../../assets/icons/cervical.webp";
import ExpertiseIcon03 from "../../assets/icons/fertilization.webp";

/* IMPORT API */
import { contactUs } from "../../service/api";
import { useState } from "react";

/* IMPORT CONFIG FILE */
import { INSTAGRAM_KEY } from "../../config";

/* VALIDATION SCHEMA */
const validationSchema = yup.object().shape({
  firstName: yup.string().required("Please provide your first name"),
  lastName: yup.string().required("Please provide your last name"),
  email: yup.string().email().required("Please provide your emmail address"),
  phone: yup.string().required("Please provide your phone number"),
  message: yup.string().required("Please provide your reason"),
});

const Homepage = () => {
  const [formSubmit, setFormSubmit] = useState(false);
  const [instagramWallData, setInstagramWallData] = useState([]);

  /* FORM VALUES HANDLING */
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setFormSubmit(true);
      const body = {
        name: values?.firstName + " " + values?.lastName,
        phone: values?.phone,
        email: values?.email,
        message: values?.message,
      };
      contactUs(body)
        .then((res) => {
          if (res?.status) {
            console.log(res?.message);
            toast.success(res?.message);
            formik.resetForm();
          } else {
            console.log(res?.message);
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          setFormSubmit(false);
        });
    },
  });

  /* USEEFFECT */
  useEffect(() => {
    axios
      .get("https://graph.instagram.com/me/media", {
        params: {
          fields:
            "id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username",
          access_token: INSTAGRAM_KEY,
          limit: 6,
        },
      })
      .then((res) => {
        if (res?.status === 200) {
          setInstagramWallData(res?.data?.data);
        }
      });
  }, []);

  return (
    <section className="homepage">
      <Toaster />

      {/* BANNER */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="banner"
      >
        <div className="container">
          <div className="row align-items-center justify-content-between top-index">
            <div className="col-xl-8 col-sm-12">
              <h1>Providing Care For Future Family</h1>
              <h4>
                Empowering families with the gift of life: IVF for a beautiful
                beginning
              </h4>
              <Button
                className="appointment-btn"
                onClick={() =>
                  window.Calendly.initPopupWidget({
                    url: "https://calendly.com/dr-kakkadvivek/appointment",
                  })
                }
              >
                SCHEDULE APPOINTMENT
              </Button>
            </div>
          </div>
        </div>
      </motion.div>

      {/* SPECIALITY */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="speciality"
      >
        <div className="container">
          <div className="box">
            <div className="row align-items-center">
              <div className="col-xl-4 col-sm-12">
                <img src={VivekProfile} alt="Vivek Kakkad" />
              </div>
              <div className="col-xl-8 col-sm-12">
                <div className="about-details">
                  <div className="mb-4">
                    <h5>DR. VIVEK KAKKAD</h5>
                    <h2>How can I help you ?</h2>
                    <h4>You deserve the best care</h4>
                  </div>
                  <div className="d-flex align-items-center mb-4">
                    <div className="cta-btns">
                      <Button className="cta">
                        <p>Make an appointment</p>
                        <div className="d-flex align-items-center mt-2">
                          <CallIcon color="#323232" size="18" />
                          <Link to={"tel:+919898283962"}>
                            <p className="number">+91 9898283962</p>
                          </Link>
                        </div>
                      </Button>
                      <Button className="cta">
                        <p>Online Schedule</p>
                        <div className="d-flex align-items-center mt-2">
                          <CalendarIcon color="#323232" size="18" />
                          <p
                            className="number"
                            onClick={() =>
                              window.Calendly.initPopupWidget({
                                url: "https://calendly.com/dr-kakkadvivek/appointment",
                              })
                            }
                          >
                            Book appointment
                          </p>
                        </div>
                      </Button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-4 col-sm-12">
                      <div className="expertise-box">
                        <img src={ExpertiseIcon} alt="Female Infertility" />
                        <h5>Male &amp; Female Infertility</h5>
                        <p>
                          My dedication to optimizing the chances of conception
                          for my patients is a testament to my commitment to
                          their health and well-being.
                        </p>
                        <Link to="/male-and-female-infertility">
                          <Button className="learn-more">LEARN MORE</Button>
                        </Link>
                      </div>
                    </div>
                    <div className="col-xl-4 col-sm-12">
                      <div className="expertise-box">
                        <img
                          src={ExpertiseIcon02}
                          alt="In-Vitro Fertilization"
                        />
                        <h5>In-Vitro Fertilization</h5>
                        <p>
                          Our approach to fertility treatments involves a
                          comprehensive evaluation of both partners to determine
                          the underlying cause of infertility.
                        </p>
                        <Link to="/ivf-and-conception-care">
                          <Button className="learn-more">LEARN MORE</Button>
                        </Link>
                      </div>
                    </div>
                    <div className="col-xl-4 col-sm-12">
                      <div className="expertise-box">
                        <img
                          src={ExpertiseIcon03}
                          alt="Reproductive Endocrinology"
                        />
                        <h5>Fertility Preservation</h5>
                        <p>
                          Fertility preservation refers to the process of
                          safeguarding reproductive potential for individuals
                          facing circumstances.
                        </p>
                        <Link to="/fertility-preservation">
                          <Button className="learn-more">LEARN MORE</Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* WHY QUESTIONS */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="why-questions"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-sm-12">
              <div className="row">
                <div className="col-xl-6 col-sm-12">
                  <Link to="/top-10-reasons-to-see-your-gynaecologist">
                    <div className="question-box">
                      <img
                        src={Question01}
                        alt="Top 10 reasons to see your gynaecologist"
                      />
                      <div className="overlay">
                        <div className="green-box">
                          <p>Top 10 reasons to see your gynaecologist</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-xl-6 col-sm-12">
                  <Link to="/top-10-reasons-to-see-your-fertility-specialist">
                    <div className="question-box">
                      <img
                        src={Question02}
                        alt="Top reasons to see your fertility specialist"
                      />
                      <div className="overlay">
                        <div className="green-box">
                          <p>Top reasons to see your fertility specialist</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-sm-12">
              <div className="questions-section">
                <h2>
                  When to see your gynaecologist or fertility specialist ?
                </h2>
                <p>
                  For many women, visiting a gynaecologist can be daunting.
                  Similarly for couples, making an appointment with a fertility
                  specialist can be intimidating. If you are in doubt, here are
                  the top 10 reasons to make an appointment today.
                </p>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* ABOUT ME */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="about-me"
      >
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-xl-5 col-sm-12">
              <img src={About} alt="About Vivek Kakkad" />
            </div>
            <div className="col-xl-6 col-sm-12">
              <h2>Dr Vivek Kakkad</h2>
              <h2 style={{ fontSize: "20px", fontWeight: "600" }}>
                Reproductive Endocrinologist and Fertility Specialist
              </h2>
              <p>
                Dr. Vivek Kakkad is a consultant at Birla Fertility &amp; IVF.
                He has over ten years of experience.
              </p>
              <p>
                Dr. Vivek Kakkad completed his medical degree from Medical
                college Baroda, Vadodara and went on to pursue his
                post-graduation in Obstetrics and Gynecology from renowned All
                India Institute Of Medical Sciences (AIIMS), New Delhi. Dr.
                Vivek pursued his M.Ch. in Reproductive Medicine and Surgery
                from Sri Ramachandra Institute of Higher Education and Research,
                Chennai and became the very first superspecialist IVF consultant
                practicing in Gujarat. Dr. Kakkad acquired professional training
                in Andrology from the University of Miami, USA, further
                enhancing his knowledge and skills in this specialized field.
                Throughout his career, Dr. Kakkad has been committed to staying
                at the forefront of the latest advances in fertility treatment
                and technology.
              </p>
              <Link to="/about-us">
                <Button className="know-more-btn">KNOW MORE</Button>
              </Link>
            </div>
          </div>
        </div>
      </motion.div>

      {/* TESTIMONIALS */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="testimonials"
      >
        <div className="container">
          <div className="heading">
            <h2>I Care About Customers Experience Too</h2>
          </div>
          <div className="row mt-5">
            <div className="col-xl-4 col-sm-12">
              <div className="testimonial-box">
                <div className="user-profile">
                  <img
                    src={require("../../assets/quote-icon.png")}
                    alt="User Profile"
                  />
                </div>
                <p>
                  We came to know about ART Fertility Clinic through Instagram.
                  We were suggested for{" "}
                  <b style={{ color: "#000" }}>IVF treatment</b> by{" "}
                  <b style={{ color: "#000" }}>Dr. Vivek Kakkad</b>. We have now
                  got pregnancy after trying for 3 years. We are very happy by
                  all the staff and Dr Vivek.
                </p>
                <h5>Anonymous Patient</h5>
              </div>
            </div>
            <div className="col-xl-4 col-sm-12">
              <div className="testimonial-box">
                <div className="user-profile">
                  <img
                    src={require("../../assets/quote-icon.png")}
                    alt="User Profile"
                  />
                </div>
                <p>
                  Thank you so much{" "}
                  <b style={{ color: "#000" }}>Dr Vivek Kakkad</b> sir. We have
                  conceived through{" "}
                  <b style={{ color: "#000" }}>IVF treatment</b> in the 1
                  <sup>st</sup> attempt. We have twins pregnancy. We were trying
                  from long time and then came to ART. All staff is extermely
                  caring in nature.
                </p>
                <h5>Farhinbanu Kazi</h5>
              </div>
            </div>
            <div className="col-xl-4 col-sm-12">
              <div className="testimonial-box">
                <div className="user-profile">
                  <img
                    src={require("../../assets/quote-icon.png")}
                    alt="User Profile"
                  />
                </div>
                <p>
                  After 4 years of marriage, we tried for pregnancy but were not
                  successful, then we came to "ART Clinic" which solved our
                  problem. <b style={{ color: "#000" }}>Dr Vivek Kakkad</b>{" "}
                  guided us for <b style={{ color: "#000" }}>IVF treatment</b>{" "}
                  which is an easy process to conceive pregnancy.
                </p>
                <h5>Anonymous Patient</h5>
              </div>
            </div>
            <div className="col-xl-4 col-sm-12">
              <div className="testimonial-box mt-5">
                <div className="user-profile">
                  <img
                    src={require("../../assets/quote-icon.png")}
                    alt="User Profile"
                  />
                </div>
                <p>
                  We came to know about ART Fertility clinic from our family
                  doctor Mrs. Rachana Shah. We came here with a hope that we
                  were going to be parents soon. We succeeded in our first
                  attempt. Thanks to{" "}
                  <b style={{ color: "#000" }}>Dr Vivek Kakkad</b> and his team.
                </p>
                <h5>Nagma Shaikh</h5>
              </div>
            </div>
            <div className="col-xl-4 col-sm-12">
              <div className="testimonial-box mt-5">
                <div className="user-profile">
                  <img
                    src={require("../../assets/quote-icon.png")}
                    alt="User Profile"
                  />
                </div>
                <p>
                  I heard about ART Fertility Clinic fron one of our relatives.
                  I met with <b style={{ color: "#000" }}>Dr Vivek Kakkad</b>{" "}
                  and they explained us the process about IVF and cleared our
                  doubts. We decided to start{" "}
                  <b style={{ color: "#000" }}>IVF treatment</b> and got sucess
                  the very first time.
                </p>
                <h5>Bhumika Dixit</h5>
              </div>
            </div>
            <div className="col-xl-4 col-sm-12">
              <div className="testimonial-box mt-5">
                <div className="user-profile">
                  <img
                    src={require("../../assets/quote-icon.png")}
                    alt="User Profile"
                  />
                </div>
                <p>
                  It was a great experience with qualified doctors and whole
                  staff. It was a very good experience with staff co-operation
                  and cleanliness too. I would always like to recommend ART
                  Clinic whosoever is planning to experience parenthood.
                </p>
                <h5>Anonymous Patient</h5>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* ABOUT VIVEK KAKKAD VIDEOS */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="about-video"
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-sm-12">
              <div className="video-box">
                <img
                  src={AboutVideoImg}
                  alt="About Vivek Kakkad"
                  style={{ borderRadius: "10px" }}
                />
                {/* <div className="overlay">
                  <Button className="play-btn">
                    <PlayIcon color="#2F71B3" size="22" />
                  </Button>
                </div> */}
              </div>
            </div>
            <div className="col-xl-6 col-sm-12">
              <div className="about-details">
                <div>
                  <h2>Why clarity matters when choosing IVF</h2>
                  <p className="tagline">
                    Choosing IVF treatment is not a quick decision. That is why
                    you deserve clarity about the tests, the procedure and the
                    expectations.
                  </p>
                  <p className="desc">
                    We offer confidential, non-judgmental sexual and
                    reproductive health care with people of any gender, age &
                    nationality.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* LATEST BLOGS */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="latest-blogs"
      >
        <div className="container">
          <div className="heading">
            <h2>Latest Blogs</h2>
            <Link to="/publications-and-blogs" className="link-item">
              View all blogs
            </Link>
          </div>
          <div className="row">
            <div className="col-xl-3 col-sm-12">
              <BlogBox
                blogImg={BlogImage01}
                blogTitle="Symptoms and Signs of Male Fertility"
                blogDesc="Male Infertility is more common than people think. Approximately 10 percent of couples..."
                blogLink="/symptoms-and-signs-of-male-infertility"
              />
            </div>
            <div className="col-xl-3 col-sm-12">
              <BlogBox
                blogImg={BlogImage02}
                blogTitle="Is IVF Procedure Painful ?"
                blogDesc="In vitro fertilization (IVF) is one of the most well-known and widely used fertility procedures...."
                blogLink="/is-ivf-procedure-painful"
              />
            </div>
            <div className="col-xl-3 col-sm-12">
              <BlogBox
                blogImg={BlogImage03}
                blogTitle="Signs of Successful Embryo Transfer"
                blogDesc="Embryo transfer is an important and final step of in vitro fertilization (IVF) treatment..."
                blogLink="/successful-embryo-transfer"
              />
            </div>
            <div className="col-xl-3 col-sm-12">
              <BlogBox
                blogImg={BlogImage04}
                blogTitle="Secondary Infertility"
                blogDesc="Find out about the secondary infertility causes, the difference between primary and secondar..."
                blogLink="/secondary-infertility"
              />
            </div>
          </div>
        </div>
      </motion.div>

      {/* INSTAGRAM WALL */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="instagram-wall"
      >
        <div className="container">
          <h2>
            Follow{" "}
            <a
              style={{ color: "#000", textDecoration: "none" }}
              href="https://www.instagram.com/guj_fertility_doc/"
            >
              @guj_fertility_doc
            </a>
          </h2>
          <div className="row mt-4">
            <div className="col-xl-4 col-sm-12">
              <a
                href={instagramWallData?.[0]?.permalink}
                target="_blank"
                rel="noreferrer"
              >
                {instagramWallData?.[0]?.media_type === "IMAGE" ? (
                  <img
                    src={instagramWallData?.[0]?.media_url}
                    alt="Follow Dr Vivek Kakkad on Instagram"
                  />
                ) : (
                  ""
                )}
                {instagramWallData?.[0]?.media_type === "VIDEO" ? (
                  <video
                    src={instagramWallData?.[0]?.media_url}
                    alt="Follow Dr Vivek Kakkad on Instagram"
                  />
                ) : (
                  ""
                )}
                {instagramWallData?.[0]?.media_type === "CAROUSEL_ALBUM" ? (
                  <img
                    src={instagramWallData?.[0]?.media_url}
                    alt="Follow Dr Vivek Kakkad on Instagram"
                  />
                ) : (
                  ""
                )}
              </a>
            </div>
            <div className="col-xl-4 col-sm-12">
              <div className="row gy-4">
                <div className="col-xl-6 col-sm-12">
                  <a
                    href={instagramWallData?.[1]?.permalink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {instagramWallData?.[1]?.media_type === "IMAGE" ? (
                      <img
                        src={instagramWallData?.[1]?.media_url}
                        className="insta-image-02"
                        alt="Follow Dr Vivek Kakkad on Instagram"
                      />
                    ) : (
                      ""
                    )}
                    {instagramWallData?.[1]?.media_type === "VIDEO" ? (
                      <video
                        src={instagramWallData?.[1]?.media_url}
                        className="insta-image-02"
                        alt="Follow Dr Vivek Kakkad on Instagram"
                      />
                    ) : (
                      ""
                    )}
                    {instagramWallData?.[1]?.media_type === "CAROUSEL_ALBUM" ? (
                      <img
                        src={instagramWallData?.[1]?.media_url}
                        className="insta-image-02"
                        alt="Follow Dr Vivek Kakkad on Instagram"
                      />
                    ) : (
                      ""
                    )}
                  </a>
                </div>
                <div className="col-xl-6 col-sm-12">
                  <a
                    href={instagramWallData?.[2]?.permalink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {instagramWallData?.[2]?.media_type === "IMAGE" ? (
                      <img
                        src={instagramWallData?.[2]?.media_url}
                        className="insta-image-02"
                        alt="Follow Dr Vivek Kakkad on Instagram"
                      />
                    ) : (
                      ""
                    )}
                    {instagramWallData?.[2]?.media_type === "VIDEO" ? (
                      <video
                        src={instagramWallData?.[2]?.media_url}
                        className="insta-image-02"
                        alt="Follow Dr Vivek Kakkad on Instagram"
                      />
                    ) : (
                      ""
                    )}
                    {instagramWallData?.[2]?.media_type === "CAROUSEL_ALBUM" ? (
                      <img
                        src={instagramWallData?.[2]?.media_url}
                        className="insta-image-02"
                        alt="Follow Dr Vivek Kakkad on Instagram"
                      />
                    ) : (
                      ""
                    )}
                  </a>
                </div>
                <div className="col-xl-6 col-sm-12">
                  <a
                    href={instagramWallData?.[3]?.permalink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {instagramWallData?.[3]?.media_type === "IMAGE" ? (
                      <img
                        src={instagramWallData?.[3]?.media_url}
                        className="insta-image-02"
                        alt="Follow Dr Vivek Kakkad on Instagram"
                      />
                    ) : (
                      ""
                    )}
                    {instagramWallData?.[3]?.media_type === "VIDEO" ? (
                      <video
                        src={instagramWallData?.[3]?.media_url}
                        className="insta-image-02"
                        alt="Follow Dr Vivek Kakkad on Instagram"
                      />
                    ) : (
                      ""
                    )}
                    {instagramWallData?.[3]?.media_type === "CAROUSEL_ALBUM" ? (
                      <img
                        src={instagramWallData?.[3]?.media_url}
                        className="insta-image-02"
                        alt="Follow Dr Vivek Kakkad on Instagram"
                      />
                    ) : (
                      ""
                    )}
                  </a>
                </div>
                <div className="col-xl-6 col-sm-12">
                  <a
                    href={instagramWallData?.[4]?.permalink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {instagramWallData?.[4]?.media_type === "IMAGE" ? (
                      <img
                        src={instagramWallData?.[4]?.media_url}
                        className="insta-image-02"
                        alt="Follow Dr Vivek Kakkad on Instagram"
                      />
                    ) : (
                      ""
                    )}
                    {instagramWallData?.[4]?.media_type === "VIDEO" ? (
                      <video
                        src={instagramWallData?.[4]?.media_url}
                        className="insta-image-02"
                        alt="Follow Dr Vivek Kakkad on Instagram"
                      />
                    ) : (
                      ""
                    )}
                    {instagramWallData?.[4]?.media_type === "CAROUSEL_ALBUM" ? (
                      <img
                        src={instagramWallData?.[4]?.media_url}
                        className="insta-image-02"
                        alt="Follow Dr Vivek Kakkad on Instagram"
                      />
                    ) : (
                      ""
                    )}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-sm-12">
              <a
                href={instagramWallData?.[5]?.permalink}
                target="_blank"
                rel="noreferrer"
              >
                {instagramWallData?.[5]?.media_type === "IMAGE" ? (
                  <img
                    src={instagramWallData?.[5]?.media_url}
                    alt="Follow Dr Vivek Kakkad on Instagram"
                  />
                ) : (
                  ""
                )}
                {instagramWallData?.[5]?.media_type === "VIDEO" ? (
                  <video
                    src={instagramWallData?.[5]?.media_url}
                    alt="Follow Dr Vivek Kakkad on Instagram"
                  />
                ) : (
                  ""
                )}
                {instagramWallData?.[5]?.media_type === "CAROUSEL_ALBUM" ? (
                  <img
                    src={instagramWallData?.[5]?.media_url}
                    alt="Follow Dr Vivek Kakkad on Instagram"
                  />
                ) : (
                  ""
                )}
              </a>
            </div>
          </div>
        </div>
      </motion.div>
    </section>
  );
};

export default Homepage;
